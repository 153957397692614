import React, { Fragment } from "react"
import Navbar from "../components/Navbar/Navbar"
import { ThemeProvider } from "@mui/material/styles"
import theme from "../theme"
import AboutHeroHeader from "../components/HeroHeader/About/About"
import Fast from "../components/Fast/Fast"
import LeaderShip from "../components/LeaderShip/LeaderShip"
import ApaceFamily from "../components/ApaceFamily/ApaceFamily"
import ApaceTheme from "../components/ApaceTheme/ApaceTheme"
import Layout from "../components/Layout"
import Nav2 from "../components/Layout/Navbar"
import Footer from "../components/Layout/Footer"
import "../styles/home.scss"
// import "../styles/global.css"
import { useState } from "react"
import { Container } from "@mui/material"

const isBrowser = typeof window !== "undefined"

const about = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  if (isBrowser) {
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 50) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Nav2 />
      <div className="new_home_container" style={{paddingTop:110}}>
        <Fast />
        <AboutHeroHeader />
        {/* <LeaderShip /> */}
        <ApaceFamily />
        <ApaceTheme />
      </div>
      <Footer />
    </ThemeProvider>
  )
}

export default about
